<template>
    <div>
        <teamList :sport_id="sport_id"></teamList>
    </div>
</template>

<script>

import teamList from '@/components/ballsports/team/list.vue';

export default {
    components: {
        teamList
    },
    data () {
        let self = (window.x = this)
        return {
            sport_id: 7,
        }
    },
    methods: {
    },
    mounted () {
    },
    watch: {},
    computed: {}
}
</script>
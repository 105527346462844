<template>
  <div>
    <Modal
        v-model="modal_add_relation"
        :title="'队伍id: ' + new_relation.id"
        @on-ok="ok"
    >
        <thirdTeamSearch :sport_id="sport_id" v-model="new_relation.third" @onSourceIdChange="newRelationSourceIdChange" ></thirdTeamSearch>
    </Modal>

    <Modal v-model="modal_edit_team" draggable footer-hide >
        <div slot="header" style="color:#f60;text-align:center; ">
            <!-- <Icon type="ios-information-circle"></Icon> -->
            <span>编辑队伍</span>
        </div>
        <div class="modal-div-containter">
            <teamEdit :sport_id="sport_id" :team_id="modal_edited_team_id" @closePage="closeEditModalTeam"></teamEdit>
        </div>
    </Modal>
    
    <Drawer
        v-model="drawer_edit"
        width="450"
        :mask-closable="true"
    >
        <teamEdit :sport_id="sport_id" :team_id="edit_team_id" @closePage="closeEditPage"></teamEdit>
    </Drawer> 
    <Row style="margin-bottom:10px;" :gutter="16" align="middle">
        <Col span="4" style="text-align: left">
            <Input v-model="search_key" placeholder="搜索" search @on-search="onSearch" />
        </Col>
        <Col span="20" style="text-align: right">
            <Button type="success" @click="openEdit" >新增</Button>
        </Col>
    </Row>
    <fitTable
        :columns="columns" 
        :data="data" 
        :loading="loading" 
    >
    </fitTable>
    <Page
        :total="total_page"
        :current="current_page"
        :page-size="page_size"
        @on-change="onPageChange"
        show-total
        show-elevator
    ></Page>
  </div>
</template>
<script>

import { getTeam, addTeamRelation, delTeamRelation } 
    from '@/api/ballsports/base/team';
import thirdTeamSearch from './third_source_search.vue';
import teamEdit from './edit.vue';
import {getImgUrl} from '@/utils/util';
import fitTable from '../../basic/table.vue';

const delButton = (vm, h, params) => {
    return h(
        'Poptip',
        {
        props: {
            confirm: true,
            title: '您确定要删除这条数据吗?',
            transfer: true
        },
        on: {
            'on-ok': () => {
                // vm.delete(params.row.id)
                // 删除本行
                vm.data.splice(params.index, 1)
            }
        }
        },
        [
        h(
            'Button',
            {
                style: {
                    margin: '2px 2px'
                },
                props: {
                    type: 'warning',
                    size: 'small'
                }
            },
            '删除'
        )
        ]
    )
}

const editButton = (vm, h, params) => {
  // let self = this;
    return h(
        'Button',
        {
            style: {
                margin: '2px 2px'
            },
            props: {
                type: 'info',
            },
            on: {
                click: () => {
                    // 路由跳转到编辑页面
                    let query = { sport_id: vm.sport_id, team_id: params.row.id }
                    // let resolve_data = vm.$router.resolve({ path: '/ballsports/base/team/detail', query: query })
                    // window.open(resolve_data.href, '_blank');
                    let sport = vm.$conf.ballsports.sports[vm.sport_id];
                    if (sport){
                        let path = '/ballsports/'+ sport.name_en +'/team/detail';
                        let resolve_data = vm.$router.resolve({ path: path, query: query });
                        window.open(resolve_data.href, '_blank')
                    }else{
                        vm.$Message.error("暂不支持的运动类型");
                    }
                }
            }
        },
        '编辑'
    )
}

export default {
    name: "team-list",
    props: {
        sport_id: Number,   // 运动id
    },
    components: {
        thirdTeamSearch,
        teamEdit,
        fitTable,
    },
    data () {
        let self = (window.x = this)
        return {
            drawer_edit: false,
            search_key: '',
            loading: true,
            total_page: 0,
            page_size: 0,
            current_page: 1,
            modal_add_relation: false,
            columns: [
                {
                    title: 'id',
                    align: 'center',
                    key: 'id',
                    width:100,
                },
                
                {
                    title: '双打(多打)',
                    align: 'center',
                    key: 'subs',
                    minWidth: 100,
                    render: function (h, params) {
                        let currentRow = params.row
                        var subs = currentRow.subs;
                        let _result = [];
                        subs.forEach(element => {
                            // _result.push( h("Row", { attrs: {justify: 'center' }}, [h('Avatar', { attrs: { src: logo? self.$conf.ballsports.img_prefix + '/v2/team/' + logo: '', size:"large" }, })],) )
                            _result.push( 
                                h("Row", { attrs: {justify: 'start' }},
                                    [
                                        // h('Col', { attrs: { span: 10 }}, 
                                        //     [
                                        //         h('router-link',
                                        //             {
                                        //                 attrs:{
                                        //                     target: "_blank", 
                                        //                 },
                                        //                 props: {
                                        //                     to: {
                                        //                         path: '/ballsports/base/team/detail',
                                        //                         query: {
                                        //                             sport_id: currentRow.sport_id,
                                        //                             team_id: element.id,
                                        //                         }
                                        //                     },
                                        //                 }
                                        //             },
                                        //             element.id,
                                        //         ),
                                        //     ] 
                                        // ),
                                        // h('Col', { attrs: { span: 14 }}, 
                                        //     [h('span', 
                                        //         {   
                                        //             class: "text-assist", 
                                        //         }, 
                                        //         element.name_lang
                                        //     )] 
                                        // ),
                                        h('span', {class: 'text-link', on: {
                                            'click':() => {
                                                self.editTeam(element.id);
                                            }
                                        } },  element.id + ' ',  ),
                                        h('span', {class: 'text-main',on: {
                                            'click':() => {
                                                self.editTeam(element.id);
                                            }
                                        } }, element.name_lang,)
                                    ] 
                                ) 
                            )
                        });
                        return _result 
                    }
                },
                {
                    title: '名称',
                    align: 'center',
                    key: 'name_lang',
                    minWidth: 80,
                },
                {
                    title: '简称',
                    align: 'center',
                    key: 'abbr',
                    minWidth: 80,
                },
                {
                    title: '国家',
                    align: 'center',
                    key: 'country',
                    minWidth: 80,
                    render: function (h, params) {
                        let currentRow = params.row
                        let country = currentRow.country || {}
                        return h('span', country.name || '')
                    }
                },
                {
                    title: '性别',
                    align: 'center',
                    key: 'gender',
                    width: 80,
                    render: function (h, params) {
                        let currentRow = params.row
                        let gender = currentRow.gender || 0;
                        return h('span', gender===1?'男': (gender===2?'女': '未知'))
                    }
                },

                {
                    title: 'logo',
                    align: 'center',
                    key: 'logo',
                    minWidth: 80,
                    render: function (h, params) {
                        let currentRow = params.row;
                        let subs = currentRow.subs;
                        if (subs && subs.length > 0){
                            let cols = [];
                            subs.forEach(element => {
                                cols.push(
                                    h('Col', { attrs: { span: 12 }}, 
                                        [h('img', { attrs: { src: getImgUrl(self.sport_id, 'team', element.logo), shape: 'square', size: 'middle' },
                                            class: {'team-logo': true} })]
                                    ),);
                            });
                            return h("Row", { attrs: {justify: 'center' }}, cols );
                        }else{
                            return h("Row", { attrs: {justify: 'center' }}, 
                                [h('img', { attrs: { src: getImgUrl(self.sport_id, 'team', currentRow.logo), size:"large" }, 
                                    class: {'team-logo': true}})],
                            )
                        }
                    }
                },
                {
                    title: '国家队',
                    align: 'center',
                    key: 'national',
                    width: 80,
                    render: function (h, params) {
                        let currentRow = params.row;
                        let national = currentRow.national;
                        return h('span', national===1?'是':'否')
                    }
                },
                // {
                //     title: '关联',
                //     align: 'left',
                //     key: 'relation',
                //     minWidth: 120,
                //     render: function (h, params) {
                //         let currentRow = params.row;
                //         let relation = currentRow.relation || {};
                //         if (relation && relation.length > 0){
                //             var _texts =  [ ]
                //             relation.forEach((element, _index) => {
                //                 let source_name = element.source_name;
                //                 _texts.push( 
                //                     h("Row", 
                //                         [h('Col', { attrs: { span: 3 }}, 
                //                             [h('Poptip', {
                //                                     props: {
                //                                         placement: 'left-start',
                //                                         confirm: true,
                //                                         transfer: true,
                //                                         title: '确定删除"'+ source_name +'"吗？',
                //                                     },
                //                                     on: {
                //                                         'on-ok': () => {
                //                                             // this.deletedMind();//调用删除方法
                //                                             self.delRelation( currentRow.id , element.source_id, element.third_id);
                //                                             relation.splice(_index, 1);
                //                                         },
                //                                         'on-cancel': () => {
                //                                         }
                //                                     }
                //                                 }, 
                //                                 [h('Icon', 
                //                                     {   
                //                                         attrs: { type: "md-trash", }, 
                //                                         // on: {
                //                                         //     'click':() => {
                //                                         //         relation.splice(_index, 1);
                //                                         //     }
                //                                         // } 
                //                                     },  
                //                                 )] 
                //                             )],
                //                         ),
                //                         h('Col', { attrs: { span: 5 }}, 
                //                             [h('span', 
                //                                 {   
                //                                     class: "text-main", 
                //                                 }, 
                //                                 element.source_name + ':',
                //                             )]
                //                         ),
                //                         h('Col', { attrs: { span: 6 }}, 
                //                             [h('span', 
                //                                 {   
                //                                     class: "text-link", 
                //                                 }, 
                //                                 element.third_id,
                //                             )] 
                //                         ),
                //                         // h('Col', { attrs: { span: 10 }}, 
                //                         //     [h('span', 
                //                         //         {   
                //                         //             class: "text-assist", 
                //                         //         }, 
                //                         //         element.third_name,
                //                         //     )] 
                //                         // ),
                //                         ]
                //                     )
                //                 )
                //             });
                //         }else{
                //             var _texts = [];
                //         }
                //         _texts.push( h("Row", 
                //                         [h('Col', { attrs: { span: 3 }}, 
                //                             [h('Icon', 
                //                                 {   
                //                                     attrs: { type: "md-add-circle", }, 
                //                                     on: {
                //                                         'click':() => {
                //                                             // 为当前联赛新增关联
                //                                             self.add(currentRow.id);
                //                                             self.edited_relation = relation;
                //                                         }
                //                                     } 
                //                                 },  
                //                             )] 
                //                         ),
                //                         ]
                //                     ) )
                //         return _texts
                //     }
                // },
                {
                    title: '操作',
                    key: 'handlers',
                    align: 'center',
                    width: 100,
                    render (h, params) {
                        return [ editButton(self, h, params), ]// delButton(self, h, params) ]
                    }
                }
            ],
            data: [],
            new_relation: {
                id: 0, // 比赛id
                source_id: '',  // 源id
                third: {
                    id: 0,      // 赛事id
                    name: '',   // 赛事名称
                }
            },
            edited_relation: [],
            edit_team_id: 0,
            modal_edit_team: false,
            modal_edited_team_id: 0,
        }
    },
    methods: {
        onPageChange (page) {
            this.current_page = page
            this.getData()
        },
        onSearch (search_key) {
            this.current_page = 1;
            this.search_key = search_key;
            this.getData();
        },
        onCategoryIdChange(value){
            let self = this;
            self.current_page = 1;
            self.getData();
        },
        onDatarangeChange(){
            let self = this;
            self.current_page = 1;
            self.getData();
        },
        onIsCurrentChange(){
            let self = this;
            self.current_page = 1;
            self.getData();
        },
        // end
        getData () {
            let self = this;
            this.loading = true;
            // 搜索日期
            let lang = sessionStorage.getItem("lang");
            // console.log("lang: ", lang);
            var params = {
                sport_id: self.sport_id,
                search_key: self.search_key,
                page: self.current_page,
                lang: lang,
            }
            getTeam(params).then(response => {
                // console.log(response)
                if (response.data.code == 0) {
                    this.data = response.data.data.list;
                    this.total_page = response.data.data.total_page;
                    this.page_size = response.data.data.page_size;
                    this.current_page = response.data.data.current_page;
                    this.loading = false;
                } else {
                    this.$Message.error(response.data.msg);
                }
            })
        },
        // end
        addRelation(_id, source_id, third_id){
            let self = this;
            let params = {
                sport_id: self.sport_id,
                id: _id,
                source_id: source_id,
                third_id: third_id
            }
            addTeamRelation(params).then(response => {
                if (response.data.code == 0) {
                    self.$Message.success('添加成功');
                } else {
                    self.$Message.error(response.data.msg);
                }
            })
        },
        // end
        delRelation(_id, source_id, third_id){
            let self = this;
            let params = {
                sport_id: self.sport_id,
                id: _id,
                source_id: source_id,
                third_id: third_id
            }
            delTeamRelation(params).then(response => {
                if (response.data.code == 0) {
                    // self.$Message.success(response.data.msg);
                } else {
                    self.$Message.error(response.data.msg);
                }
            })
        },
        // end 
        add ( _id ) {
            this.new_relation["id"] = _id;
            this.modal_add_relation = true;
        },
        // end
        newRelationSourceIdChange(source_id, source_name){
            this.new_relation.source_id = source_id;
            this.new_relation.source_name = source_name;
        },
        ok () {
            // 为联赛新增加第三方的关联
            let self = this;
            if (self.new_relation && self.new_relation.third){
                self.addRelation(self.new_relation.id, self.new_relation.source_id, self.new_relation.third.id)
                self.edited_relation.push(
                    {
                        source_id: self.new_relation.source_id,
                        source_name: self.new_relation.source_name,
                        third_id: self.new_relation.third.id,
                        third_name: self.new_relation.third.name,
                    }
                )
            }
        },
        // delete (_id) {
        //     var params = {
        //         id: _id,
        //         type: this.data_type
        //     }
        //     deleteData(params).then(response => {
        //         if (response.data.code == 0) {
        //             return true
        //         } else {
        //             this.$Message.error(response.data.msg)
        //             return false
        //         }
        //     })
        // },
        // end 
        openEdit(){
            let self = this;
            self.edit_team_id=0;
            self.drawer_edit = true;
        },
        // end
        closeEditPage(){
            let self = this;
            self.drawer_edit = false;
        },
        // end 
        closeEditModalTeam(){
            let self = this;
            self.modal_edit_team = false;
            self.modal_edited_team_id = 0;
        },
        // end
        editTeam(_id){
            let self = this;
            self.modal_edited_team_id = _id;
            self.modal_edit_team = true;
        },
        // end
    },
    mounted () {
        this.getData();
    },
    watch: {
    },
    computed: {}
}
</script>
<style scoped>
    @import "list.css"
</style>